import Vue from "vue";
import Router from "vue-router";

import SideBar from "@/layouts/SideBar.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

import auth from "../middleware/auth";
import log from "../middleware/log";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",

  routes: [
    {
      path: "/",
      redirect: "/dashboardall",
    },
    {
      path: "/login",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "login",
          component: () => import("@/pages/login"),
          meta: {
            middleware: log,
          },
        },
      ],
    },
    {
      path: "/twofactor",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "twofactor",
          component: () => import("@/pages/twofactor"),
          meta: {
            middleware: log,
          },
        },
      ],
    },

    {
      path: "",
      component: SideBar,
      children: [
        {
          path: "/dashboardall",
          name: "สรุปภาพรวม",
          component: () => import("@/pages/dashboardall"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/admin",
          name: "ผู้ดูแล ADMIN",
          component: () => import("@/pages/admin"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/member",
          name: "สมาชิก",
          component: () => import("@/pages/member"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/followcustomer",
          name: "ติดตามลูกค้า",
          component: () => import("@/pages/followcustomer"),
          meta: {
            middleware: [auth, log],
          },
        },

        {
          path: "/depositerror",
          name: "รายการฝาก",
          component: () => import("@/pages/depositerror"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/historywithdraw",
          name: "รายการถอนเงิน",
          component: () => import("@/pages/historywithdraw"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/withdraw",
          name: "รายการแจ้งถอน",
          component: () => import("@/pages/withdraw"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/historycredit",
          name: "รายการคืนยอดเสีย",
          component: () => import("@/pages/historycredit"),
          meta: {
            middleware: [auth, log],
          },
        },

        {
          path: "/coupon",
          name: "ระบบคูปอง",
          component: () => import("@/pages/coupon"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/couponspin",
          name: "ระบบคูปองกงล้อ",
          component: () => import("@/pages/couponspin"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/affiliate",
          name: "ระบบพันธมิตร",
          component: () => import("@/pages/affiliate"),
          meta: {
            middleware: [auth, log],
          },
        },

        {
          path: "/statement",
          name: "Statement",
          component: () => import("@/pages/statement"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/bank",
          name: "ธนาคาร",
          component: () => import("@/pages/bank"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/website",
          name: "ตั้งค่าเว็บไซต์",
          component: () => import("@/pages/website"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/system",
          name: "ตั้งค่าธนาคาร",
          component: () => import("@/pages/system"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/blacklist",
          name: "บัญชีดำ",
          component: () => import("@/pages/blacklist"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/log",
          name: "สถานะของระบบ",
          component: () => import("@/pages/log"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/notify",
          name: "ตั้งค่าการแจ้งเตือน",
          component: () => import("@/pages/notify"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/sms",
          name: "ส่งข้อความ",
          component: () => import("@/pages/sms"),
          meta: {
            middleware: [auth, log],
          },
        },

        {
          path: "/telegram",
          name: "เทเลแกรม",
          component: () => import("@/pages/telegram"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/lotto",
          name: "หวย",
          component: () => import("@/pages/lotto"),
          meta: {
            middleware: [auth, log],
          },
        },
        {
          path: "/summary",
          name: "สรุปภาพรวมรายเดือน",
          component: () => import("@/pages/summary"),
          meta: {
            middleware: [auth, log],
          },
        },
      ],
    },
    {
      path: "/*",
      component: AuthLayout,
      children: [
        {
          path: "",
          name: "notfound",
          component: () => import("@/pages/notfound"),
        },
      ],
    },
  ],
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  document.title = "TUKTUK888 | " + to.name || "ระบบจัดการ";

  window.scrollTo(0, 0);
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;
